export enum BillingQuotePeriod {
  annual = 'annual',
  monthly = 'monthly',
}

export enum BillingQuoteCurrency {
  GBP = 'GBP',
  EUR = 'EUR',
  USD = 'USD',
  AUD = 'AUD',
  CAD = 'CAD',
  NZD = 'NZD',
}

export interface IBillingQuoteSchema {
  amount: number;
  currency: BillingQuoteCurrency;
  period: BillingQuotePeriod;
  numberOfProperties: number;
  airdnaPlan: boolean;
}
