import { cached } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { action } from '@ember/object';
import { getOwner } from '@ember/application';
import BaseController from 'uplisting-frontend/pods/base/controller';
import OnboardModel from 'uplisting-frontend/models/onboard';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import {
  countriesList,
  countriesPhoneCodes,
  isSaveDisabled,
  getCountryFlagEmoji,
} from 'uplisting-frontend/utils';
import type IndexRoute from 'uplisting-frontend/pods/index/route';

interface ICountry {
  flag: string;
  name: string;
  code: number;
  nameAndCode: string;
}

export default class RegisterInfoController extends BaseController {
  @service('repositories/onboard')
  onboardRepository!: Services['repositories/onboard'];

  countriesList = countriesList;

  @cached
  get isAirdnaUser(): boolean {
    return this.currentUser.isAirdnaUser;
  }

  @cached
  get title(): string {
    return `register_info.${this.isAirdnaUser ? 'airdna_title' : 'title'}`;
  }

  @cached
  get description(): string {
    return `register_info.${this.isAirdnaUser ? 'airdna_description' : 'description'}`;
  }

  @cached
  get selectedPhoneCodeCountry(): ICountry | undefined {
    return this.countries.find(
      (country) => country.name === this.changeset.countryCodeName,
    );
  }

  @cached
  get countries(): ICountry[] {
    const { intl } = this;

    return Object.entries(countriesPhoneCodes).map(([code, phoneCode]) => ({
      flag: getCountryFlagEmoji(code as keyof typeof countriesPhoneCodes),
      name: intl.t(`countries_list.${code}`),
      code: phoneCode,
      nameAndCode: `${intl.t(`countries_list.${code}`)} +${phoneCode}`,
    }));
  }

  @cached
  get changeset(): GenericChangeset<OnboardModel> {
    const { onboardRepository } = this;

    const record = onboardRepository.createRecord(
      onboardRepository.defaultParams,
    );

    return onboardRepository.buildChangeset(record);
  }

  @action
  handleSelectCountryCallingCode(country: ICountry): void {
    this.changeset.countryCallingCode = country.code;
    this.changeset.countryCodeName = country.name;
  }

  @action
  async handleFormSubmit(): Promise<void> {
    const saveDisabled = await isSaveDisabled(this.changeset);

    if (saveDisabled) {
      return;
    }

    try {
      await this.changeset.save();

      await this.session.fetchCurrentUser({ reload: true });

      if (this.isAirdnaUser) {
        const url = this.router.urlFor('calendar.welcome.airdna.start');

        window.open(url, '_self');
      } else if (this.currentUser.onboardRoute) {
        await this.router.transitionTo(this.currentUser.onboardRoute);
      } else {
        const indexRoute = getOwner(this)?.lookup('route:index') as IndexRoute;

        await indexRoute.redirectAfterLogin();
      }
    } catch {
      this.notifications.error();
    }
  }
}
