import Model, { attr } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import {
  BillingQuoteCurrency,
  BillingQuotePeriod,
  type IBillingQuoteSchema,
} from 'uplisting-frontend/models/schemas';

export default class BillingQuoteModel
  extends Model
  implements IBillingQuoteSchema
{
  @attr('number') amount!: number;
  @attr('string') currency!: BillingQuoteCurrency;
  @attr('string') period!: BillingQuotePeriod;
  @attr('number') numberOfProperties!: number;
  @attr('boolean') airdnaPlan!: boolean;

  @cached
  get isAnnual(): boolean {
    return this.period === BillingQuotePeriod.annual;
  }

  @cached
  get isMonthly(): boolean {
    return this.period === BillingQuotePeriod.monthly;
  }

  @cached
  get isAirdnaPlan(): boolean {
    return this.airdnaPlan;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'billing-quote': BillingQuoteModel;
  }
}
