import config from 'ember-get-config';
import { action } from '@ember/object';
import { type Registry as Services, service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import BaseController from 'uplisting-frontend/pods/base/controller';
import { type ModelRegistry } from 'ember-data/types/registries/model';
import { BillingQuotePeriod } from 'uplisting-frontend/models/schemas';

export default class AccountBillingController extends BaseController {
  @service pricing!: Services['pricing'];
  @service features!: Services['features'];
  @service('repositories/billing-quote')
  billingQuoteRepository!: Services['repositories/billing-quote'];

  @cached @tracked customer!: ModelRegistry['customer'];
  @cached @tracked seamConnections!: ModelRegistry['seam-connection'][];
  @cached @tracked changeoverSettings!: ModelRegistry['changeover-setting'][];
  @cached @tracked billingQuotes!: ModelRegistry['billing-quote'][];

  @cached
  @tracked
  securityDepositSettings!: ModelRegistry['security-deposit-setting'][];

  @cached
  @tracked
  guestIdentityVerificationConfiguration!: ModelRegistry['guest-identity-verification-configuration'];

  @cached @tracked isCheckingOut = false;
  @cached @tracked isCancelling = false;
  @cached @tracked isLoading = false;

  @cached
  get numberOfProperties(): number | undefined {
    return this.billingQuotes[0]?.numberOfProperties;
  }

  @cached
  get numberOfPropertiesDecreased(): number {
    if (this.numberOfProperties) {
      return this.numberOfProperties - 1;
    }

    return 0;
  }

  @cached
  get isAirdnaPlan(): boolean {
    return this.billingQuotes[0]?.isAirdnaPlan || false;
  }

  @cached
  get annualPrice(): number {
    return this.billingQuotes.find((quote) => quote.isAnnual)?.amount || 0;
  }

  @cached
  get monthlyPrice(): number {
    return this.billingQuotes.find((quote) => quote.isMonthly)?.amount || 0;
  }

  @cached
  get annualDiscount(): number {
    return this.nonDiscountedAnnualPrice - this.annualPrice;
  }

  @cached
  get nonDiscountedAnnualPrice(): number {
    return this.monthlyPrice * 12;
  }

  @cached
  get discountPercentage(): number {
    if (this.nonDiscountedAnnualPrice === 0) {
      return 0;
    }

    return Math.round(
      (this.annualDiscount / this.nonDiscountedAnnualPrice) * 100,
    );
  }

  @cached
  get displayDiscount(): boolean {
    return this.discountPercentage > 0;
  }

  @cached
  get selectedPlanTotal(): number {
    return this.customer.annual ? this.annualPrice : this.monthlyPrice;
  }

  @cached
  get smartLocksActive(): boolean {
    return (
      this.seamConnections.filter(
        (connection) => connection.seamLockables.length,
      ).length !== 0
    );
  }

  @cached
  get securityDepositActive(): boolean {
    return this.securityDepositSettings.some((setting) => setting.enabled);
  }

  @cached
  get guestIdentityActive(): boolean {
    return this.guestIdentityVerificationConfiguration.isEnabled;
  }

  @cached
  get cleaningActive(): boolean {
    return this.changeoverSettings.some((setting) => setting.enabled);
  }

  @cached
  get currencyClass(): string {
    return `${this.pricing.currency} bold`;
  }

  @action
  async handleSelectCurrency(currency: string): Promise<void> {
    await this.refreshBillingQuotes(currency);

    this.customer.currency = currency;

    this.billingQuotes = this.billingQuoteRepository
      .peekAll()
      .filter((quote) => quote.currency === currency);
  }

  @action
  async refreshBillingQuotes(currency: string): Promise<void> {
    const hasQuoteForCurrency = this.billingQuoteRepository
      .peekAll()
      .some((quote) => quote.currency === currency);

    if (hasQuoteForCurrency) {
      return;
    }

    this.isLoading = true;

    try {
      await this.billingQuoteRepository.query({
        currency,
        periods: [BillingQuotePeriod.annual, BillingQuotePeriod.monthly],
      });
    } catch {
      this.notifications.error();
    } finally {
      this.isLoading = false;
    }
  }

  @action
  handlePlanTypeChanged(annual: boolean): void {
    this.customer.annual = annual;
  }

  @action
  async handleCheckout(): Promise<void> {
    if (this.isCheckingOut) {
      return;
    }

    this.isCheckingOut = true;

    if (window.Rewardful?.referral) {
      this.customer.referral = window.Rewardful.referral;
    }

    try {
      await this.customer.save();

      window.open(this.customer.checkoutUrl, '_self');
    } catch {
      this.notifications.error();
    } finally {
      this.isCheckingOut = false;
    }
  }

  @action
  async handleCancel(): Promise<void> {
    if (this.isCancelling) {
      return;
    }

    this.isCancelling = true;

    try {
      const cancellation = this.store.createRecord('customer-cancellation');

      await cancellation.save();

      window.churnkey.init('show', {
        customerId: cancellation.customerId,
        authHash: cancellation.hmac,
        appId: config.churnkey.appId,
        mode: config.churnkey.mode,
        provider: 'stripe',
        record: true,
      });
    } catch {
      this.notifications.error();
    } finally {
      this.isCancelling = false;
    }
  }
}
