import type Transition from '@ember/routing/transition';
import ProtectedRoute from 'uplisting-frontend/pods/base/protected-route';
import CompSetsController from 'uplisting-frontend/pods/comp-sets/controller';

export default class CompSetsRoute extends ProtectedRoute {
  async beforeModel(transition: Transition): Promise<void> {
    await super.beforeModel(transition);

    if (!this.currentUser.isAccountOwner) {
      transition.abort();

      window.open('/', '_self');
    }
  }

  model() {
    return this.store.findAll('airbnb-official-connection');
  }

  setupController(
    controller: CompSetsController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { airbnbOfficialConnections: model.slice() });
  }
}
