import PermissionModel from 'uplisting-frontend/models/permission';
import CustomerModel from 'uplisting-frontend/models/customer';
import ClientModel from 'uplisting-frontend/models/client';

export enum UserRoles {
  accountOwner = 'account_owner',
  host = 'host',
  housekeeper = 'housekeeper',
  owner = 'owner',
}

export enum UserOnboardStatus {
  needsReview = 'needs_review',
  complete = 'complete',
}

export interface IUserSchema {
  name: string;
  email: string;
  role: UserRoles;
  userHash: string;
  trialist: boolean;
  unreadMessages: number;
  lapsed: boolean;
  pendingAlerts: number;
  gaClientId: string;
  identities: string[];
  invitation: string;
  onboardStatus?: UserOnboardStatus;
  airdnaRedirectClick: boolean;
  airdnaUserId?: number;

  client: ClientModel;
  customer?: CustomerModel;
  permissions: PermissionModel[];
}
